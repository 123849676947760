import React from 'react';
import { FaChartPie, FaNetworkWired, FaUserFriends, FaUserSecret } from "react-icons/fa";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';
import Globe from 'react-globe.gl';
import * as d3 from "d3";
import './Card.css';
import './Dashboard.css';
import './SideMenu.css';
import ReactResizeDetector from 'react-resize-detector';


export default class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fraudProfiles: [],
      loading: true,
      numProfiles: 0,
      numUsers: 0,
      numUserData: 0,
      numIpAddresses: 0,
    };

    this.renderGlobe = this.renderGlobe.bind(this);
    this.renderUserTableRows = this.renderUserTableRows.bind(this);
    this.renderDashboardSection = this.renderDashboardSection.bind(this);
  }

  componentDidMount() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);

    fetch('http://localhost:8000/api/fraudtracking/v1/fraudprofiles/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${userToken.token}`
      }
    }).then(response => {
      if (response.ok) {
        return response.json();
      }
    }).then(data => {
      let results = data['results'];
      let numUsers = 0;
      let numIpAddresses = 0;
      let numUserData = 0;
      let numProfiles = results.length;
      for (let i = 0; i < numProfiles; i++) {
        let profile = results[i];
        numUsers += profile['users'].length;
        numIpAddresses += profile['ip_records'].length;
        numUserData += profile['personal_data_records'].length;
      }
      this.setState({
        loading: false,
        fraudProfiles: data['results'],
        numUsers: numUsers,
        numIpAddresses: numIpAddresses,
        numUserData: numUserData,
        numProfiles: numProfiles,

    });
    }).catch((error) => {
      console.error(error);
    })
  }

  renderGlobe() {
    if (this.state.loading) {
      return <div align="middle"><Spinner animation="border" /></div>
    } else {
      let IPdata = {};
      let unitLength = 1 / this.state.numIpAddresses;
      for (let i = 0; i < this.state.fraudProfiles.length; i++) {
        let currentProfile = this.state.fraudProfiles[i];
        for (let j = 0; j < currentProfile['ip_records'].length; j++) {
          let currentIP = currentProfile['ip_records'][j];
          let ip = currentIP['ip_record']['ip_address'];
          if (ip in IPdata) {
            IPdata[ip]['size'] += unitLength;
          } else {
            IPdata[ip] = {size: unitLength, lat: currentIP['ip_record']['latitude'], lng: currentIP['ip_record']['longitude'], label: ip, color: '#f98125'};
          }
        }
      }
      let globeData = []
      for (let key in IPdata) {
        globeData.push(IPdata[key]);
      }

      const weightColor = d3.scaleSequentialSqrt(d3.interpolateYlOrRd).domain([0, 0.5]);
      return <ReactResizeDetector handleWidth handleHeight>{
        ({width, height}) => <Globe
          width={width}
          height={height}
          globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
          bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
          backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
          pointsData={globeData}
          pointAltitude="size"
          pointColor={d => {return weightColor(d.size)}}
        />
      }
    </ReactResizeDetector>
    }
  }

  renderUserTableRows() {
    if (this.state.loading) {
      return <div align="middle"><Spinner animation="border" /></div>
    } else {
      let rows = [];
      let users = [];
      for (let i = 0; i < this.state.fraudProfiles.length; i++) {
        for (let k = 0; k < this.state.fraudProfiles[i]['users'].length; k++) {
          users.push(this.state.fraudProfiles[i]['users'][k]);
        }
      }
      for (let j = 0; j < users.length; j++) {
        rows.push(
          <div>
            <div className="table-link">
              <Row className='table-row'>
                <Col md={1} className="user-table-cell">{users[j]['id']}</Col>
                <Col md={4} className="user-table-cell">{users[j]['email']}</Col>
                <Col md={2} className="user-table-cell">{users[j]['first_name']}</Col>
                <Col md={3} className="user-table-cell">{users[j]['last_name']}</Col>
                <Col md={2} className="user-table-cell">{users[j]['account_type']}</Col>
              </Row>
            </div>
            <hr/>
          </div>
        );
      }
      return rows;
    }
  }

  renderDashboardSection() {
    return (<>
      <Row style={{paddingTop: '1rem', paddingLeft: '1rem', paddingRight: '1rem'}}>
        <Col md={3}>
          <Card>
            <Card.Header>Profiles Constructed</Card.Header>
            <Card.Body>
              <h1 className="big-text" align="middle">{this.state.numProfiles}</h1>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Card.Header>Users Detected</Card.Header>
            <Card.Body>
              <h1 className="big-text" align="middle">{this.state.numUsers}</h1>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Card.Header>User Data Detected</Card.Header>
            <Card.Body>
              <h1 className="big-text" align="middle">{this.state.numUserData}</h1>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Card.Header>IPs Detected</Card.Header>
            <Card.Body>
              <h1 className="big-text" align="middle">{this.state.numIpAddresses}</h1>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br/>
      <Row style={{paddingLeft: '1rem',paddingRight: '1rem'}}>
        <Col md={12}>
          <Card>
            <Card.Header>Fraud Locations Detected</Card.Header>
            <Card.Body>
              {this.renderGlobe()}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      </>);
  }

  render() {
    return (
      <>
      <Container fluid>
        <Row style={{paddingTop: '1rem'}}>
          <Col className='menu-panel' md={1}>
            <div className="menu-header">
              <hr/>
              <p className="menu-header-text">HEIMDALL</p>
              <hr/>
            </div>
            <div className="menu-element">
                <div style={{display: 'inline-block'}}><FaChartPie size={28} color={'#f98125'}/></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text active-element">Dashboard</p></div>
              <hr/>
            </div>
            <div className="menu-element">
              <a href="/profiles">
                <div style={{display: 'inline-block'}}><FaUserSecret  size={28}/></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text">Profiles</p></div>
              </a>
              <hr/>
            </div>
            <div className="menu-element">
              <a href="/users">
                <div style={{display: 'inline-block'}}><FaUserFriends  size={28}/></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text">People</p></div>
              </a>
              <hr/>
            </div>
            <div className="menu-element">
              <a href="/ipaddresses">
                <div style={{display: 'inline-block'}}><FaNetworkWired  size={28}/></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text">IP Data</p></div>
              </a>
              <hr/>
            </div>
          </Col>
          <Col md={11}>
            {this.renderDashboardSection()}
          </Col>
        </Row>
        </Container>
      </>
    );
  }
}
