import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import './Card.css';
import './Login.css';

import { login } from '../../api/auth';

export default function LoginPage({ setToken }) {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [errors, setErrors] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    const loginResponse = await login(username, password);
    if (loginResponse.status === 200) {
      setToken(loginResponse.data);
    } else {
      setErrors(loginResponse.data);
    }
  }

  let usernameErrors = [];
  let passwordErrors = [];
  let otherErrors = [];
  if (errors) {
    if ('username' in errors) {
      for (let i = 0; i < errors['username'].length; i++) {
        // usernameErrors.push(errors['username'][i]);
        usernameErrors.push(
          <Form.Control.Feedback key={`username_error_${i}`} type="invalid">{errors['username'][i]}</Form.Control.Feedback>
        );
      }
    }
    if ('password' in errors) {
      for (let i = 0; i < errors['password'].length; i++) {
        // passwordErrors.push(errors['password'][i]);
        passwordErrors.push(
          <Form.Control.Feedback key={`password_error_${i}`} type="invalid">{errors['password'][i]}</Form.Control.Feedback>
        );
      }
    }
    if ('non_field_errors' in errors) {
      for (let i = 0; i < errors['non_field_errors'].length; i++) {
        otherErrors.push(
          <Form.Control.Feedback key={`other_error_${i}`} type="invalid">{errors['non_field_errors'][i]}</Form.Control.Feedback>
        );
        otherErrors.push(errors['non_field_errors'][i]);
      }
    }
  }

  return (
    <Container>
      <div className="login-wrapper">
        <Card className='login-card'>
          <Card.Header>
            <Row>
              <Col md={8}>
              <h1 className='login-header'>HEIMDALL</h1>
              </Col>
              <Col md={4} align='right'>
                <Image src="shield_gold.png" rounded fluid style={{width: '35%'}}/>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={e => setUsername(e.target.value)}
                  style={{borderRadius: 2}}
                  required
                />
                { usernameErrors }
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    onChange={e => setPassword(e.target.value)}
                    style={{borderRadius: 2}}
                    required
                  />
                  { passwordErrors }
                </Form.Group>
              <Form.Group style={{color: '#ed2f2f'}}>{ otherErrors }</Form.Group>
              <Col md={12} align='right'>
                <Button
                  variant="primary"
                  type="submit"
                  className='login-button'
                  style={{backgroundColor: '#1ba2d8', borderColor: '#1ba2d8', borderRadius: 2, letterSpacing: '1px'}}
                >
                  Login
                </Button>
              </Col>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
}

LoginPage.propTypes = {
  setToken: PropTypes.func.isRequired
}