import React from 'react';
import { FaChartPie, FaNetworkWired, FaUserFriends, FaUserSecret } from "react-icons/fa";

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import './Card.css';
import './Dashboard.css';
import './SideMenu.css';



export default class FraudProfileListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fraudProfiles: [],
      loading: true,
    };

    this.renderProfileRows = this.renderProfileRows.bind(this);
  }

  componentDidMount() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);

    fetch('http://localhost:8000/api/fraudtracking/v1/fraudprofiles/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${userToken.token}`
      }
    }).then(response => {
      if (response.ok) {
        return response.json();
      }
    }).then(data => {
      let results = data['results'];
      this.setState({fraudProfiles: results, loading: false});
    });
  }

  renderProfileRows() {
    if (this.state.loading) {
      return (<tr>
        <td colSpan={6} align={'center'}>
          <Spinner animation="border" />
        </td>
      </tr>);
    } else {
      let rows = [];
      
      for (let i = 0; i < this.state.fraudProfiles.length; i++) {
        let currentProfile = this.state.fraudProfiles[i];
        let users = [];
        for (let k = 0; k < currentProfile['users'].length; k++) {
          users.push(<li>{currentProfile['users'][k]['email']}</li>);
        }
        let row = (<tr>
          <td>{currentProfile['id']}</td>
          <td>{currentProfile['created_at']}</td>
          <td><ul>{users}</ul></td>
          <td><Button variant="info">View</Button></td>
        </tr>);
        rows.push(row);
      }
      return rows;
    }
  }

  render() {
    return (
    <>
      <Container fluid>
        <Row style={{paddingTop: '1rem'}}>
          <Col className='menu-panel' md={1}>
            <div className="menu-header">
              <hr/>
              <p className="menu-header-text">HEIMDALL</p>
              <hr/>
            </div>
            <div className="menu-element">
              <a href="/dashboard">
                <div style={{display: 'inline-block'}}><FaChartPie size={28}/></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text">Dashboard</p></div>
              </a>
              <hr/>
            </div>
            <div className="menu-element">
              <a href="/profiles">
                <div style={{display: 'inline-block'}}><FaUserSecret  size={28} color={'#f98125'}/></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text active-element">Profiles</p></div>
              </a>
              <hr/>
            </div>
            <div className="menu-element">
              <a href="/users">
                <div style={{display: 'inline-block'}}><FaUserFriends  size={28}/></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text">People</p></div>
              </a>
              <hr/>
            </div>
            <div className="menu-element">
              <a href="/profiles">
                <div style={{display: 'inline-block'}}><FaNetworkWired  size={28}/></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text">IP Data</p></div>
              </a>
              <hr/>
            </div>
          </Col>
          <Col md={11}>
            <Row style={{paddingTop: '1rem', paddingLeft: '1rem', paddingRight: '1rem'}}>
                <Col md={12}>
                  <Card>
                    <Card.Header>Profiles Constructed</Card.Header>
                    <Card.Body>
                      <Row>
                        <Col md={1}>ID</Col>
                        <Col md={1}>Created At</Col>
                        <Col md={3}>Users</Col>
                        <Col md={3}>IP Addresses</Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
            </Row>
          </Col>
        </Row>
        </Container>
      </>
    );
  }
};