
export async function login(username, password) {
  let response = await fetch(
    process.env.REACT_APP_LOGIN_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({username: username, password: password})
    }
  );
  let data = await response.json();
  return {data: data, status: response.status};
}
